@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/Pretendard-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Pretendard-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Pretendard-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Pretendard-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/Pretendard-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Sunflower";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Sunflower-Medium.ttf") format("truetype");
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh; /* 최소 높이 설정 */
  overflow-x: hidden; /* 가로 스크롤 방지 */
  font-family: Arial, sans-serif;
  background-color: #fff;
}

body {
  font-family: "Pretendard";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: "Pretendard";
}

